export const Constants = {
  DEVELOPMENT_BASE_URL: "https://cms-api-dev.hoabl.in/",
  QA_BASE_URL: "https://cms-api-stg.hoabl.in/",
  PREPRODUCTION_BASE_URL: "https://cms-api-uat.hoabl.in/",
  PRODUCTION_BASE_URL: "https://cms-api.hoabl.in/",

  GOOGLE_MAP_KEY: "AIzaSyBAQrIpZqIE4moMhptpOYD9Wa9585l9ju0",

  AUTHORIZATION_TOKEN: "AuthorizationToken",
  LOGGED_IN_USER: "LoggedInUser",
  FIRSTNAME: "Firstname",
  LASTNAME: "Lastname",

  ERROR_COLOR: "#f45353",

  MAX_MOBILE_NUMBER_DIGITS: 10,
  MAX_OTP_DIGITS: 6,
  SNACKBAR_DURATION: 5000,

  MAX_RESEND_OTP_TIMER: 120, // 2 minutes

  HOABL_WEB_LINK: "https://hoabl.in/",

  HOABL_HELP_CENTER_MAIL: "care@hoabl.in",

  GOOGLE_PLAY_STORE_URL:
    "https://play.google.com/store/apps/details?id=com.hoabl",
  APPLE_APP_STORE_URL:
    "https://apps.apple.com/in/app/the-house-of-abhinandan-lodha/id1630999647",

  FIRST_MSG:
    "You have incorrectly typed the OTP 1 time. Click on Resend OTP to receive OTP again",
  SECOND_MSG:
    "You have incorrectly typed the OTP 2 times. Click on Resend OTP to receive OTP again",
  THIRD_MSG:
    "You have incorrectly typed the OTP 3 times. Click on Resend OTP to receive OTP again",
  FOURTH_MSG:
    "You have incorrectly typed the OTP 4 times. Click on Resend OTP to receive OTP again",
  FIFTH_MSG:
    "You have incorrectly typed the OTP 5 times. Click on Resend OTP to receive OTP again",
};
