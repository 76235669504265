import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isEmpty,
  isOtpValid,
  isOnlyNumberAllowed,
} from "utils/ValidationUtils";
import { Constants } from "constants/Constants";
import { MuiLoadingButton } from "components/mui-buttons/MuiButtons";
import { TroubleSigninAction } from "redux-container/login/trouble-signingin-redux/TroubleSigninginRedux";
import {
  troubleSigninPayload,
  TroubleSigninValidate,
} from "../LoginInitialValues";
import useForm from "app/hooks/useForm";
import MuiFormDialog from "components/mui-dialogs/MuiFormDialog";
import TroubleSigningForm from "pages/authentication/TroubleSigningForm";
import MuiActionStatusDialog from "components/mui-dialogs/MuiActionStatusDialog";
import MuiTextField from "components/mui-textfield/MuiTextfield";
import VerifyOtpActions from "redux-container/login/verify-otp/VerifyOtpRedux";
import GenerateOtpActions from "redux-container/login/generate-otp/GenerateOtpRedux";
import MuiSnackbar from "components/mui-snackbar/MuiSnackbar";
import styles from "../Login.module.css";
import NumberListDialog from "components/number-list-dialog/NumberListDialog";
import { KeyCodeTypeEnum } from "enumerations/KeyCodeTypeEnum";

const actionDispatch = (dispatch: any) => {
  return {
    verifyOtp: (data: any) => dispatch(VerifyOtpActions.verifyOtpRequest(data)),
    generateOtp: (data: any) =>
      dispatch(GenerateOtpActions.generateOtpRequest(data)),
    setGenerateOtpState: (key: any, data: any) =>
      dispatch(GenerateOtpActions.setGenerateOtpState(key, data)),
    setVerifyOtpState: (key: any, data: any) =>
      dispatch(VerifyOtpActions.setVerifyOtpState(key, data)),
    addTroubleSigninRequest: (data: any) =>
      dispatch(TroubleSigninAction.addTroubleSigninRequest(data)),
    setTroubleSigninState: (key: any, value: any) =>
      dispatch(TroubleSigninAction.setTroubleSigninState(key, value)),
  };
};

const VerifyOTP = ({ fullScreen }: any) => {
  const {
    verifyOtp,
    generateOtp,
    setGenerateOtpState,
    setVerifyOtpState,
    addTroubleSigninRequest,
    setTroubleSigninState,
  } = actionDispatch(useDispatch());

  const otpErrorMessage = "Please enter a valid 6 digit OTP.";

  const [counter, setCounter] = useState(30);
  const [initialTimer, setInitialTimer] = useState(45);

  const {
    isResponseDialogOpen,
    isDialogOpen,
    errorMessage,
    isLoading,
    successMessage,
    isSuccess,
    isCountryCodeDialogOpen,
    isVerifyOtpLoading,
  } = useSelector((state: any) => ({
    isResponseDialogOpen: state?.troubleSignin?.isResponseDialogOpen,
    isDialogOpen: state?.troubleSignin?.isDialogOpen,
    isLoading: state?.troubleSignin?.isLoading,
    isSuccess: state?.troubleSignin?.isSuccess,
    isCountryCodeDialogOpen: state?.troubleSignin?.isCountryCodeDialogOpen,
    successMessage: state?.troubleSignin?.message,
    errorMessage: state?.troubleSignin?.error,
    isVerifyOtpLoading: state?.verifyOtp?.isLoading,
  }));
  const { handleSubmit, resetForm, valid, setFieldValue, ...formData } =
    useForm({
      initialValues: troubleSigninPayload,
      validate: TroubleSigninValidate,
    });
  const [IPaddress, setIPaddress] = useState("");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isTimerVisible, setIsTimerVisible] = useState(true);

  const mobileNumber = useSelector(
    (state: any) => state?.generateOtp.mobileNumber
  );
  const isWhatsappCommunicationAllowed = useSelector(
    (state: any) => state?.generateOtp.isWhatsappCommunicationAllowed
  );
  const isOtpVerified = useSelector(
    (state: any) => state?.verifyOtp.isOtpVerified
  );
  const tempCode = useSelector((state: any) => state?.verifyOtp?.tempCode);
  const error = useSelector((state: any) => state?.verifyOtp.error);
  const message = useSelector((state: any) => state?.verifyOtp.message);
  const fetchDeviceIP = async () => {
    await fetch("https://geolocation-db.com/json/")
      .then((response: any) => response.json())
      .then((data: any) => setIPaddress(data.IPv4));
  };

  const handleChangeOTP = (event: any) => {
    if (isOnlyNumberAllowed(event.target.value)) {
      setOtp(event.target.value.toString());
    }
  };

  const openCountryCodeDialog = () => {
    setTroubleSigninState("isCountryCodeDialogOpen", true);
  };

  const verifyOTP = () => {
    // if (!isOtpValid(otp)) {
    //   setOtpError(otpErrorMessage);
    //   return false;
    // }
    const requestBody = {
      otpNumber: otp,
      phoneNumber: mobileNumber,
      countryCode: "+91",
      whatsappConsent: isWhatsappCommunicationAllowed,
      deviceToken: "customer_web",
    };
    setVerifyOtpState("tempCode", otp);
    verifyOtp(requestBody);
  };

  const resendOtp = () => {
    if (initialTimer !== Constants.MAX_RESEND_OTP_TIMER) {
      setInitialTimer((timer: number) => {
        return timer + 15;
      });
      setCounter(initialTimer);
    } else {
      setCounter(Constants.MAX_RESEND_OTP_TIMER);
    }

    const requestBody = {
      phoneNumber: mobileNumber,
      countryCode: "+91",
      country: "IN",
    };
    generateOtp(requestBody);
    setIsTimerVisible(true);
  };

  const handleFormDialogOpen = () => {
    setFieldValue("phoneNumber", mobileNumber);
    setTroubleSigninState("isDialogOpen", true);
  };

  const handleFormDialogClose = () => {
    setTroubleSigninState("isResponseDialogOpen", false);
    resetForm(troubleSigninPayload);
    setTroubleSigninState("isDialogOpen", false);
  };

  const onFormSubmit = (values: typeof troubleSigninPayload) => {
    addTroubleSigninRequest(values);
    setTroubleSigninState("isResponseDialogOpen", true);
    setTroubleSigninState("isDialogOpen", false);
  };

  useEffect(() => {
    fetchDeviceIP();
  }, []);

  useEffect(() => {
    if (counter === 0) {
      setIsTimerVisible(false);
    }
    const timer: any =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (isOtpVerified) {
      setTimeout(() => {
        setVerifyOtpState("isOtpVerified", false);
        setVerifyOtpState("tempCode", "");
      }, 5000);
    }
  }, [isOtpVerified]);

  const onEditClick = () => {
    setGenerateOtpState("isOtpGenerated", false);
    setVerifyOtpState("error", null);
    setVerifyOtpState("isOtpVerified", false);
  };

  const getNoOfAttempts = () => {
    if (Constants.FIRST_MSG.match(error)) {
      return " (4 attempts left)";
    } else if (Constants.SECOND_MSG.match(error)) {
      return " (3 attempts left)";
    } else if (Constants.THIRD_MSG.match(error)) {
      return " (2 attempts left)";
    } else if (Constants.FOURTH_MSG.match(error)) {
      return " (1 attempts left)";
    } else if (Constants.FIFTH_MSG.match(error)) {
      return " (0 attempts left)";
    } else {
      return "";
    }
  };

  const handleOnEnterKeyPress = (event: any) => {
    if (event.code === KeyCodeTypeEnum.EnterKey) {
      verifyOTP();
    }
  };

  return (
    <div className={styles["form-holder"]}>
      <div>
        <h2 className={styles["form-heading"]}>Verify OTP</h2>

        <div className={styles["otp-instruction-holder"]}>
          <p className={styles["otp-instruction-text"]}>
            Enter 6-digit OTP sent to
            <span onClick={onEditClick}>+91-{mobileNumber}</span>
          </p>

          <span onClick={onEditClick}>EDIT</span>
        </div>

        <div className={styles["resend-otp-btn-holder"]}>
          <MuiTextField
            fullWidth
            label={`Enter OTP${getNoOfAttempts()}`}
            placeholder="Type here"
            name="otp"
            onKeyPress={handleOnEnterKeyPress}
            autoFocus={isOtpVerified ? false : true}
            disabled={isOtpVerified ? true : false}
            focused
            value={isOtpVerified ? tempCode : otp}
            sx={{
              ".MuiInputBase-input": {
                letterSpacing: 10,
              },
            }}
            onChange={handleChangeOTP}
            // onBlur={handleOnBlurOTP}
            helperText={otpError}
            error={otpError.length > 0 ? true : false}
            className="margin-top-55"
            inputProps={{
              maxLength: Constants.MAX_OTP_DIGITS,
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            InputProps={{
              endAdornment: (
                <button
                  className={
                    counter === 0
                      ? styles["resend-otp-btn"]
                      : styles["resend-otp-btn-disable"]
                  }
                  onClick={resendOtp}
                >
                  RESEND OTP
                </button>
              ),
            }}
          />
        </div>

        {!isOtpVerified && isTimerVisible && counter !== 0 ? (
          <div className={styles["resend-otp"]}>
            RESEND OTP in <span>00:{counter} sec</span>
          </div>
        ) : null}

        <div className={styles["btn-holder"]}>
          <MuiLoadingButton
            loading={isVerifyOtpLoading}
            disabled={isEmpty(otp) ? true : false}
            variant="contained"
            onClick={verifyOTP}
            autoFocus
            disableElevation
            className={isEmpty(otp) ? "btn btn-disabled" : "btn btn-dark"}
          >
            Verify OTP
          </MuiLoadingButton>
        </div>
      </div>

      <div className={styles["trouble-signing-in-holder"]}>
        <button
          className={styles["trouble-signing-in"]}
          onClick={handleFormDialogOpen}
        >
          Trouble Signing in?
        </button>
      </div>

      <MuiFormDialog
        fullScreen={fullScreen}
        maxWidth={"lg"}
        formheading="Trouble Signing In?"
        open={isDialogOpen}
        handleclose={handleFormDialogClose}
        handleformsubmit={handleSubmit(onFormSubmit)}
        isformlayout={true}
        formbuttondisabled={!valid}
        children={
          <TroubleSigningForm
            formProps={{ ...formData, setFieldValue }}
            handleAdormentClick={openCountryCodeDialog}
          />
        }
      />

      {!isLoading && (
        <MuiActionStatusDialog
          maxWidth={"sm"}
          isconfirmdialog={false}
          issuccess={isSuccess}
          responsemessage={isSuccess ? successMessage : errorMessage}
          message={"We will contact you shortly."}
          open={isResponseDialogOpen}
          handleclose={handleFormDialogClose}
        />
      )}

      <NumberListDialog
        open={isCountryCodeDialogOpen}
        onClick={() => setTroubleSigninState("isCountryCodeDialogOpen", false)}
      />

      <MuiSnackbar
        shouldOpen={isOtpVerified}
        message={message}
        isSuccess={true}
        closeHandler={() => {
          setVerifyOtpState("isOtpVerified", false);
          setVerifyOtpState("tempCode", "");
        }}
      />

      <MuiSnackbar
        shouldOpen={error !== null ? true : false}
        message={error}
        isSuccess={false}
        closeHandler={undefined}
      />
    </div>
  );
};

export default VerifyOTP;
